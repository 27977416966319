
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {AppCommonPlayerCategory} from "@/models/app/common/player-category";
import {AppAdministratorPlayersRootListIndex} from "@/models/app/administrator/players/root/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {ElCol, ElRow,} from "element-plus";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
  },
})
export default class AppAdministratorPlayersCategoriesListIndexVue extends Vue {
  isLoading: boolean | null = false;

  playerCategories: Array<AppCommonPlayerCategory> = [];
  players: Array<AppAdministratorPlayersRootListIndex> = [];

  getPlayerCategory(data): string {
    const yearOfBirth = moment(data).format("YYYY");
    const age = moment().diff(yearOfBirth, 'years');
    const category = this.playerCategories.find(item => (age >= item.content.from && age <= item.content.to));

    if (category) {
      return category.content.name;
    }

    return "N/A";
  }

  getAvatar(data): string {
    return data ? data : "/images/other/placeholder-avatar.jpg";
  }

  async getPlayerCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/player-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.playerCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async getPlayers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/organization-members`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        role: "player",
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.players = r.data.sort(function (a, b) {
          if (a.content.name < b.content.name) {
            return -1;
          }
          if (a.content.name > b.content.name) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getPlayerCategories();
    await this.getPlayers();
  }
}
